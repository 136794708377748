import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
    Accueil: "/",
    "Consultation en Analytics": "/fr/consultation-analytique",
    "Configuration de Google Analytics": "/fr/configuration-de-google-analytics"
  };
  
// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-analytics-setup"
);

  const AnalyticsSetup = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title={`Configurer Google Analytics à partir de zéro ! [Guide ${currentYear}]`}
          description="Guide sur la configuration d'une installation moderne de Google Analytics. Configuration des objectifs, suivi des événements et meilleures pratiques. Tutoriel par un consultant certifié Google Analytics."
          lang="fr"
          image="google-analytics-setup-realtime-report-hero"
          alternateLangs={alternateLangs}
          canonical="/fr/configuration-de-google-analytics"
        />
        <MainContent article>
          <ImgScreenshot
            src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
            alt="homme travaillant sur la configuration de Google Analytics avec un ordinateur portable"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Guide de configuration de Google Analytics</H>
<p>
  L'une des premières tâches pour un nouveau site web est de configurer Google Analytics. Après tout le temps et les efforts que vous avez investis dans la création d'un site web, vous voulez bien sûr mesurer et analyser le comportement des utilisateurs. Vous pouvez avoir des questions comme :
</p>

<ul>
  <li>Comment mon contenu est-il performant ?</li>
  <li>Quelle catégorie de produit est la plus populaire ?</li>
  <li>Combien de conversions ma campagne Facebook a-t-elle déclenché ?</li>
  <li>Les visiteurs regardent-ils réellement la vidéo que j'ai produite ?</li>
  <li>Y a-t-il des pages qui performent particulièrement mal ?</li>
  <li>Les visiteurs partagent-ils quelque chose sur les réseaux sociaux ?</li>
  <li>Les gens abandonnent-ils pendant le processus de paiement ?</li>
</ul>

<p>
  Toutes les suppositions sur le comportement des utilisateurs ainsi que sur la performance des campagnes doivent être validées. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% de tous les sites web sur Internet utilisent Google Analytics</a> pour cela. En fait, il a une <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">part de marché de 84% dans le marché des outils d'analyse de trafic</a>, donc vous n'avez pas à vous inquiéter de choisir le mauvais outil d'analyse.
</p>
<p>
  Un outil d'analyse web vous permet de prendre des décisions basées sur des données plutôt que de deviner. De telles décisions peuvent concerner par exemple le type de contenu dans lequel investir ou le budget à consacrer aux campagnes publicitaires.
</p>
<p>
  Il est donc essentiel de configurer Google Analytics de manière professionnelle, afin de suivre les bons indicateurs de performance clés (KPI) pour votre analyse. Avec une telle configuration en place, vous aurez de nombreuses métriques disponibles pour approfondir vos analyses.
</p>
<H as="h2">Types de configurations Google Analytics</H>
<p>
  Le type de site web est un facteur clé pour une configuration Google Analytics :
  <br />
  Vous dirigez une boutique en ligne ou vendez des produits ? <br />
  Alors {/* <Link to="/fr/ecommerce-analytics"> */}
  l'analyse e-commerce{/* </Link> */} est la voie à suivre. Elle est plus orientée produit et transaction et vous fournira des informations utiles que vous ne voulez pas manquer.
</p>
<p>
  Si vous gérez un site axé sur le contenu de votre marque, alors une configuration typique de suivi Google Analytics est le bon choix. <br />
  Vous pouvez suivre les interactions des utilisateurs avec le <Link to="/fr/suivi-des-événements">suivi des événements</Link> et surveiller les indicateurs clés de performance du site web avec{" "}
  <Link to="/fr/configuration-de-google-analytics">les objectifs Google Analytics</Link>.
</p>

<p>
  Les configurations d'analyse e-commerce nécessitent plus de données sur les produits et les transactions. C'est pourquoi la configuration est un peu plus complexe, impliquant du JavaScript personnalisé pour construire une soi-disant{" "}
  <Link to="/fr/couche-de-données">couche de données</Link>
  . <br />Une couche de données est essentiellement un magasin de données, qui contient des informations sur chaque page. Google Analytics les reconnaîtra et les utilisera lorsqu'elles seront disponibles.
</p>
<p>
  Ne vous inquiétez pas pour l'instant si vous réalisez que vous devez configurer Google Analytics pour un site e-commerce. Vous pouvez activer la fonctionnalité e-commerce plus tard et également améliorer la couche de données, une fois que vous êtes prêt à le faire.{" "}
  <br />
  D'ici là, les rapports e-commerce resteront vides, mais les autres rapports fonctionneront très bien.
</p>

<H as="h2">Où obtenir le code de suivi Google Analytics ?</H>
<p>
  Visitez{" "}
  <a href="https://analytics.google.com" target="_blank" rel="noopener">
    analytics.google.com
  </a>{" "}
  et cliquez sur le bouton <strong>Inscription</strong> pour créer un compte Google Analytics. Vous obtiendrez le code de suivi Google Analytics, une fois que vous aurez suivi les étapes ci-dessous.
</p>
<ImgScreenshot
  src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
  alt="capture d'écran de l'inscription à Google Analytics"
  className="article-img"
/>
<ul>
  <li>
    Après avoir cliqué sur le bouton <strong>inscription</strong>, cliquez encore et choisissez un nom de propriété et une URL suivis par votre industrie et votre fuseau horaire.{" "}
  </li>
  <li>
    Je recommande de <strong>désélectionner toutes les options de partage de données</strong>, si vous n'êtes pas complètement au courant de votre politique de confidentialité. Mais cela signifie que Google ne <strong>partagera pas</strong> les rapports sur les données démographiques et les intérêts de l'audience avec vous. <br />
    Si vous souhaitez utiliser ces rapports, vous devrez peut-être mettre à jour votre politique de confidentialité. Cela vise à informer les utilisateurs que vous partagez des données sur leur comportement de navigation avec Google.
  </li>
  <li>
    Une fois que vous avez terminé, vous recevrez votre <strong>ID de suivi Google Analytics</strong> (par exemple,{" "}
    <em>UA-141112345-1</em>). En même temps, vous recevrez le code à insérer dans le code source de toutes vos pages.
  </li>
</ul>
<H as="h2">Comment implémenter Google Analytics ?</H>
<p>Il existe généralement deux façons d'implémenter Google Analytics :</p>

<ol>
  <li>
    Vous collez le code de suivi Google Analytics dans le code source de toutes les pages. Vous pouvez le faire page par page ou le coller dans un fichier de modèle commun. Cela pourrait être l'en-tête, la navigation ou le pied de page que toutes les pages ont en commun.
    <br />
    <strong>Je ne recommande pas</strong> cette façon d'implémenter Google Analytics, sauf si vous voulez le faire rapidement et facilement et ne plus jamais toucher à la configuration analytique. <br />
    La plupart des configurations de suivi sont effectuées plus rapidement et plus facilement avec un <Link to="/fr/gestion-des-tags">système de gestion des balises</Link>. C'est un processus plus facile et aussi plus flexible, donc cela vaut la peine d'introduire <em>un autre outil</em> dans la pile.
  </li>

  <li>
    Vous utilisez un système de gestion des balises, comme Google Tag Manager, qui est assez populaire.
    <br />
    Il existe d'autres alternatives comme <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> ou{" "}
    <em>Matomo</em> (open source). <br />
    En général, ils servent tous le même objectif et sont très similaires. Vous pouvez implémenter Google Analytics avec un système de gestion des balises non-Google sans souci. Google Tag Manager est simplement celui auquel tout le monde peut facilement accéder et qui dispose du plus de ressources disponibles en ligne.
    <br />
    Si vous voulez en savoir plus à ce sujet, consultez mon <Link to="/fr/configuration-de-google-tag-manager">tutoriel sur Google Tag Manager</Link>.
  </li>
</ol>

<H as="h2">Comment implémenter Google Analytics avec Google Tag Manager ?</H>
<p>
  Pour <strong>l'implémentation de Google Analytics via Google Tag Manager,</strong> suivez ces étapes :
</p>

<ul>
  <li>
    Visitez{" "}
    <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
      tagmanager.google.com
    </a>{" "}
    et créez un compte.{" "}
  </li>{" "}
</ul>
<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-create-account.png"
  alt="Création de compte Google Tag Manager"
  className="article-img"
/>

<ul>
  <li>
    Avec le compte, vous allez créer un conteneur pour votre site web, alors assurez-vous de sélectionner <strong>Web</strong>{" "}
    pour son utilisation :
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
  alt="Capture d'écran de la création d'un conteneur web dans Google Tag Manager"
  className="article-img"
/>

<p>
  Ensuite, vous recevrez le <strong>code snippet Google Tag Manager</strong> pour l'implémentation :
</p>

<ImgScreenshot
  src="google-analytics-setup/google-tag-manager-code-snippet.png"
  alt="Code snippet Google Tag Manager"
  className="article-img"
/>

<p>
  Ajoutez la première partie dans la balise <code>&lt;head&gt; </code>
  sur toutes les pages de votre site web. L'autre partie est ajoutée à l'intérieur de la balise <code>&lt;body&gt;</code>.
</p>
<p>Une fois le snippet Google Tag Manager (GTM) ajouté, la configuration analytique peut être configurée en un clin d'œil !</p>

<ul>
  <li>
    Dans GTM, cliquez sur <strong>balises</strong> dans la navigation de gauche et <strong>ajoutez une nouvelle balise</strong>. Un
    overlay glissera depuis le côté et vous pourrez donner un <strong>nom</strong> à la balise, par exemple{" "}
    <em>Suivi Google Analytics</em>.
  </li>
  <li>Ensuite, cliquez sur la boîte de configuration de la balise :</li>

  <ImgScreenshot
    src="google-analytics-setup/google-tag-manager-create-new-tag.png"
    alt="création d'une nouvelle balise dans Google Tag Manager"
    className="article-img"
  />

  <li>
    Une liste de types de balises apparaîtra à l'écran. <br />
    Sélectionnez la balise <strong>Google Analytics - Universal Analytics</strong> et commencez la configuration.
  </li>

  <ImgContainerFixed width="462px">
    <ImgScreenshot
      src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
      alt="création de balise Google Analytics dans Google Tag Manager"
      className="article-img"
    />
  </ImgContainerFixed>

  <li>
    Gardez le <strong>Type de suivi</strong> sur <strong>Pageview</strong>. <br />
  </li>
  <li>
    Ensuite, <strong>créez une nouvelle variable de paramètres Google Analytics.</strong>
  </li>

  <ImgScreenshot
    src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
    alt="Variable de paramètres Google Tag Manager pour Google Analytics"
    className="article-img"
  />

  <li>
    Pour configurer la variable de paramètres, donnez-lui d'abord un <strong>nom</strong>. Juste après, collez l'{" "}
    <strong>ID de suivi Google Analytics</strong> (<em>UA-141112345-1</em>), que vous avez{" "}
    <Link to="#ou-obtenir-le-code-de-suivi-google-analytics">reçu précédemment</Link>.
  </li>

  <ImgScreenshot
    src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
    alt="ID de suivi Google Tag Manager pour Google Analytics"
    className="article-img"
  />

  <li>
    Cliquez sur <strong>enregistrer</strong>.
  </li>
  <li>
    De retour dans la configuration de la balise, cliquez sur la section <strong>déclenchement</strong> pour choisir un déclencheur.
  </li>

  <li>
    Sélectionnez <strong>toutes les pages</strong>, car nous voulons que le code snippet Google Analytics s'exécute sur toutes les pages.
  </li>

  <li>
    <strong>Enregistrez</strong> le déclencheur ainsi que la configuration de la balise et revenez à la vue d'ensemble de Google Tag Manager.
  </li>

  <li>
  Cliquez sur le bouton bleu <strong>soumettre</strong> dans le coin supérieur droit. Donnez une{" "}
  <strong>nom</strong> à votre première version de conteneur et cliquez sur <strong>publier</strong>. <br />
  Vous avez maintenant implémenté avec succès Google Analytics avec Google Tag Manager !
</li>
</ul>

<p>
  Vous devriez voir des données arriver dans votre propriété Google Analytics après environ une heure.
  <br />
  Vous pouvez également consulter le rapport en temps réel pour voir s'il y a déjà des données provenant de votre visite actuelle.
</p>
<ImgScreenshot
  src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
  alt="Vue d'ensemble du rapport en temps réel de Google Analytics"
/>

<p>
  Vous avez remarqué que vous pourriez implémenter d'autres outils ou scripts et les déclencher sur <strong>toutes les pages</strong>, en seulement quelques minutes ? <br /> J'espère que cela vous fait réaliser la facilité et la puissance d'un système de gestion des balises.
</p>

<H as="h2">Comment configurer des objectifs dans Google Analytics ?</H>
<p>
  Maintenant que vous recevez des données de visiteurs, il est temps de configurer des <strong>objectifs Google Analytics</strong>.
</p>
<p>Il est probable que vous vendez quelque chose sur votre site web ou qu'il y a un formulaire que les gens peuvent remplir. </p>
<p>
  Normalement, après une commande, les utilisateurs atterrissent sur une <strong>page de remerciement</strong>. Elle dit généralement merci pour la commande et liste quelques détails de commande. <br /> Regardez dans la barre d'adresse et voyez quelle URL cette page a.{" "}
</p>
<p>Par exemple, cela pourrait ressembler à ceci :</p>
<p>
  <code>https://www.yoursite.com/thank-you.php</code>{" "}
</p>
<p>
  Configurons un objectif pour cette adresse et comptons combien de fois cette page est visitée pour indiquer combien de commandes ont eu lieu.
</p>

<ul>
  <li>
    Allez dans la <strong>zone d'administration</strong> de Google Analytics avec une vue d'ensemble de votre compte, propriété et vue. Cliquez sur <strong>objectifs</strong> dans la section <strong>vue</strong>.
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/google-analytics-admin-area-goals.png"
  alt="Objectifs de la zone d'administration de Google Analytics"
  className="article-img"
/>

<ul>
  <li>
    <strong>Ajoutez</strong> un nouvel objectif et sélectionnez <strong>personnalisé</strong> dans la configuration de l'objectif. Ensuite, <strong>continuez</strong>.
  </li>
  <li>
    Donnez un <strong>nom</strong> à votre objectif, par exemple <em>Abonnement à la newsletter</em> ou{" "}
    <em>Formulaire de commande reçu</em> et sélectionnez le type comme <strong>destination</strong>. Ensuite, <strong>continuez</strong>.
  </li>
  <li>
    Sous <strong>destination</strong> est <strong>égal à</strong>, ajoutez le <strong>chemin URL</strong>, donc dans notre exemple :
    <p>
      <code>/merci.php</code>
    </p>
    Vous pouvez également être astucieux et sélectionner <strong>Expression régulière</strong> comme type de correspondance dans le menu déroulant.
    <br />
    Si vous ne savez pas ce que sont les <strong>expressions régulières</strong> : Ce sont un moyen puissant et pratique de correspondance des chaînes de caractères. Vous pouvez apprendre à les utiliser en une heure ou deux :{" "}
    <a href="https://regexone.com/" target="_blank" rel="noopener">
      RegexOne
    </a>
  </li>
  <li>
    Vous pouvez éventuellement attribuer une <strong>valeur</strong> à votre objectif. Je le recommanderais, si vous pouvez attribuer une valeur numérique à chacune de ces pages de remerciement.
    <br /> Il y a plusieurs façons de faire cela. La plus simple est de regarder les revenus mensuels provenant de ces pages de remerciement et de les diviser par le nombre total de pages vues.
    <br />
    Cela vous donnera la moyenne pour chaque fois que l'objectif est atteint.
  </li>
  <li>
    <strong>Configurez un entonnoir</strong> si le parcours utilisateur est censé atteindre certaines pages spécifiques avant la conversion. Cela a beaucoup de sens pour les formulaires divisés en plusieurs étapes. Vous pouvez vérifier plus tard si une proportion de visiteurs abandonne quelque part dans l'entonnoir. <br />
    Vous pouvez ajouter des étapes à l'entonnoir en donnant un <strong>nom</strong> à chaque étape et un <strong>chemin de page</strong> (<em>voir ci-dessous</em>). <br />
    Notez que, si vous avez choisi <strong>égal à</strong> dans le menu déroulant de la destination supérieure, vous devez utiliser le même type de correspondance pour vos règles d'entonnoir.
    <br />
    </li>
    <li>
  Ou si vous avez choisi les <strong>expressions régulières</strong>, vous devez suivre la syntaxe regex dans vos règles de correspondance de page.
</li>
<li>
  Avant de sauvegarder, cliquez sur <strong>vérifier l'objectif</strong>. <br />
  Si vous avez déjà des données dans votre propriété GA, il les analysera et vérifiera les correspondances dans les données historiques. <br />
  Si vous n'avez aucune correspondance, mais savez qu'il devrait y en avoir, vous avez probablement fait une erreur en spécifiant les chemins des pages.
  <br /> C'est donc un bon endroit pour vérifier que les règles fonctionnent, avant de terminer la configuration de l'objectif.
</li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-google-analytics-goals.png"
  alt="Configuration des objectifs dans Google Analytics"
  className="article-img"
/>

<p>
  Félicitations ! Vous venez de configurer votre objectif et pouvez suivre les conversions d'objectifs à partir de maintenant ! <br />
  Les objectifs doivent être configurés pour les conversions les plus importantes de votre site web. Si d'autres conversions sont moins critiques ou peut-être ne sont-elles pas représentées par leur propre chemin de page, vous pouvez configurer des événements Google Analytics.
  <br />
  Après avoir configuré un événement, vous pouvez l'utiliser comme base pour vos conversions d'objectifs.
</p>

<H as="h2">Configurer des événements dans Google Analytics</H>
<p>
  Le processus de <Link to="/fr/suivi-des-événements">configuration des événements dans Google Analytics</Link> peut varier considérablement selon chaque événement. En fonction de l'interaction et de la technologie du site web, il y a différentes étapes à suivre. Ci-dessous, vous trouverez une liste d'exemples de suivi d'événements.
</p>
<H as="h3">Exemples de suivi d'événements</H>

<ul>
  <li>partager votre contenu sur les réseaux sociaux</li>
  <li>jouer la vidéo sur votre page d'accueil</li>
  <li>cliquer sur le bouton retour vers les résultats de recherche Google</li>
  <li>faire défiler jusqu'en bas de votre article</li>
  <li>cliquer sur votre email de contact</li>
  <li>ajouter un produit au panier</li>
  <li>sélectionner une variation de couleur d'un produit</li>
</ul>

<p>
  La procédure pour configurer le suivi du défilement est différente de celle pour suivre les clics de souris. Sur les sites web dynamiques, les éléments HTML ne sont pas toujours visibles. Ainsi, le suivi des clics nécessiterait une approche différente de celle sur les sites web statiques. <br />
  Le point est, il y a de nombreux facteurs influents, donc j'ai écrit un article séparé sur le suivi des événements. J'y vais en détail, étape par étape, et explique comment suivre les clics sur les boutons ou d'autres éléments. Consultez-le ici :
</p>
<p>
  <Link to="/fr/suivi-des-événements">Suivi des événements dans Google Analytics</Link>
</p>
<H as="h2">Configurer le suivi des campagnes dans Google Analytics</H>
<p>
  Le suivi des campagnes est déjà intégré dans chaque configuration de Google Analytics. Vous devez simplement ajouter des <Link to="/fr/wiki-analytics/utm_paramètre">paramètres UTM</Link> aux liens dans vos campagnes par email ou sur les réseaux sociaux.
</p>
<p>
  Il y a cinq paramètres que vous pouvez ajouter à n'importe quel lien pointant vers votre site web. Le suivi des campagnes intégré de Google Analytics reconnaît un utilisateur provenant d'un tel lien. Il attribue ensuite la visite à la campagne spécifiée et vous pouvez la trouver dans le rapport de campagne.
</p>
<p>
  Prenons l'URL <code>https://www.yoursite.com/campaign</code>
  <br />
  et les paramètres de suivi de campagne suivants comme exemple :
</p>

<table className="table table-striped table-bordered">
  <thead>
    <tr>
      <th>Paramètre UTM</th>
      <th>Valeur du paramètre</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>UTM Source</td>
      <td>Medium</td>
    </tr>
    <tr>
      <td>UTM Medium</td>
      <td>Article invité</td>
    </tr>
    <tr>
      <td>UTM Campagne</td>
      <td>Ventes de printemps</td>
    </tr>
    <tr>
      <td>UTM Terme</td>
      <td>Bottes</td>
    </tr>
    <tr>
      <td>UTM Contenu</td>
      <td>Bottes quand il fait chaud dehors</td>
    </tr>
  </tbody>
</table>

<p>
  Voici la <strong>même URL incluant les paramètres UTM</strong> :
</p>
<p>
  <code>
    https://www.yoursite.com/campaign?<strong>UTM_source</strong>
    =medium&amp;<strong>UTM_medium</strong>
    =guest-post&amp;<strong>UTM_campaign</strong>=spring-sales&amp;
    <strong>UTM_term</strong>=boots&amp;<strong>UTM_content</strong>
    =boots-when-its-warm-outside
  </code>
</p>
<p>
  Vous pouvez l'utiliser sur les réseaux sociaux ou sur d'autres sources qui renvoient vers votre site. Google Analytics suit automatiquement combien de clics arrivent à partir d'un tel lien.
</p>
<p>
  Remarquez les <strong>cinq paramètres UTM</strong> dans l'URL ? Vous n'avez pas besoin de les ajouter manuellement. Il existe{" "}
  <a
    href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
    target="_blank"
    title="Outil pour créer des liens avec des paramètres UTM"
  >
    des outils de création d'UTM
  </a>{" "}
  qui le font pour vous. Vous leur donnez simplement le <strong>lien vers votre site web</strong> et les valeurs des{" "}
  <strong>paramètres UTM</strong>. Ensuite, ils vous donneront le lien de suivi de la campagne, incluant tous les paramètres UTM.
</p>
{/* <p>
      J'ai écrit un article séparé sur{" "}
      <Link href="/fr/suivi-de-campagne">
        le suivi des campagnes UTM
      </Link>
      , où j'explique les différents paramètres UTM, comment les trouver dans Google
      Analytics et comment établir un workflow pour gérer le suivi des campagnes
      régulièrement. <br />
      Vous pouvez le lire ici :
    </p>

    <p>
      <Link to="/fr/suivi-de-campagne">
        Suivi des campagnes dans Google Analytics
      </Link>
    </p>
    <H as="h2">Meilleures pratiques de Google Analytics</H> */}


<H as="h2">Meilleures pratiques de Google Analytics</H>
<p>
  Voici une liste de <strong>meilleures pratiques</strong> supplémentaires pour configurer Google Analytics. Je les applique rigoureusement
  pour chaque configuration GA que je réalise. Elles ne sont pas seulement <em>agréables à avoir</em>, mais plutôt nécessaires pour maximiser
  la qualité des données. <br />
  Les filtres peuvent également être configurés dans le système de gestion des balises. En fait, je préfère cela, car JavaScript permet
  des règles plus avancées lors du filtrage. Néanmoins, les filtres dans Google Analytics sont plus simples et font presque aussi bien le travail.
</p>
<ol start="1">
  <li>
    <H as="h3">Exclure son propre trafic de Google Analytics</H>
    Maintenant que vous suivez les visites, excluez vos propres sessions de votre trafic web. Votre comportement de navigation diffère de celui des utilisateurs réguliers et fausserait autrement vos données. Cela compte particulièrement pour les pages à faible trafic, car vos propres pages vues pèsent plus lourdement dans l'ensemble.
    <br />
    Pour <strong>exclure votre propre trafic</strong>, vous pouvez vous filtrer par <strong>adresse IP</strong>,
    si vous avez une IP statique.
    <br />
    Sinon, vous pouvez envisager d'en obtenir une de votre fournisseur d'accès internet, car cela ne coûte généralement pas cher.
  </li>
</ol>
<ul>
  <li>
    Pour <strong>configurer l'exclusion d'IP dans Google Analytics</strong>, allez dans le <strong>panneau d'administration</strong> et
    cliquez sur <strong>Filtres</strong> dans la colonne <strong>vue</strong> à droite.
  </li>
  <li>
    Ajoutez un <strong>nom</strong> à votre filtre et sélectionnez le <strong>type de filtre</strong> comme étant{" "}
    <strong>personnalisé</strong>.
  </li>
  <li>
    Choisissez l'option de filtre <strong>exclure</strong> et sélectionnez <strong>Adresse IP</strong> dans le menu déroulant.
  </li>
  <li>
    Ensuite, entrez votre IP statique dans le champ <strong>modèle de filtre</strong> et cliquez sur <strong>enregistrer</strong>.
  </li>
</ul>

<ImgContainerFixed width="415px">
  <ImgScreenshot
    src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
    alt="Exclure l'IP de la configuration de Google Analytics"
    className="article-img"
  />
</ImgContainerFixed>

<ol start="2">
  <li>
    <H as="h3">Transformer les chemins de page en minuscules</H>
    Une autre astuce consiste à <strong>transformer automatiquement vos chemins de page en minuscules</strong>.
    <br />
    Les humains font des erreurs et à un moment donné, quelqu'un créera une URL avec des majuscules dans son nom. Si
    le site web renvoie ensuite le même contenu, que ce soit pour la même URL avec des lettres minuscules, les choses deviennent désordonnées.{" "}
    <br /> Laissez-moi expliquer. <br />
    Nous envoyons essentiellement des données sous deux chemins de page différents, bien que les données concernent la même page.
    <br />
    Les chemins de page écrits avec des casses variées conduisent à une analyse désordonnée dans Google Analytics. Toute écriture unique du
    même chemin sera affichée sur sa propre ligne dans les rapports. Après un certain temps, vous finirez par regarder plusieurs lignes
    et leurs métriques, même si elles devraient être combinées. <br />
    Si vous obtenez également des backlinks vers la même page, mais que l'adresse dans le lien était écrite de manière étrange,
    le désordre est parfait. Essayons donc de garder notre vie sans drame et de régler cela à l'avance.
  </li>
</ol>
<ul>
  <li>
    Pour <strong>transformer les chemins URL en minuscules</strong>, rendez-vous à nouveau dans les <strong>Filtres</strong> pour votre vue
    et ajoutez un nouveau filtre. Donnez-lui un nom et sélectionnez le type de filtre <strong>personnalisé</strong>.
  </li>
  <li>
    Choisissez maintenant <strong>Minuscule</strong> et sélectionnez <strong>URI de demande</strong> dans le menu déroulant.
  </li>
</ul>

<ImgContainerFixed width="369px">
  <ImgScreenshot
    src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
    alt="Configurer des URLs en minuscules dans Google Analytics"
    className="article-img"
  />
</ImgContainerFixed><br />

<ul>
  <li>
    Cliquez sur <strong>enregistrer</strong> pour terminer. Solution simple, qui évite bien des maux de tête.
  </li>
</ul>
<ol start="3">
  <li>
    <H as="h3">Structure de vue Google Analytics</H>
    <p>
      Une <strong>vue</strong> est l'endroit où vous accédez à tous vos rapports Google Analytics. Si des filtres sont configurés
      pour votre vue, tous les rapports seront filtrés en conséquence.
      <br />
      Votre <strong>structure de vue</strong> devrait généralement imiter la structure de votre organisation. Si vos marchés cibles
      sont par exemple plutôt régionaux que basés sur les pays, configurez vos vues de la même manière. Cela sera utile lorsque les indicateurs clés de performance pour les régions seront directement accessibles.
      <br />
      Une autre orientation est la structure de votre organisation pour le budget et les efforts de marketing. Si cela se fait
      au niveau des pays, il est probablement judicieux de suivre cette structure pour les vues également.
      <br />
      Vous pouvez créer jusqu'à 25 vues par propriété. Ainsi, en fonction du nombre de marchés et de canaux marketing, plusieurs structures de vues sont possibles.
    </p>
    <H as="h3">Exemple : structure de vue Google Analytics pour les sites locaux</H>
    <p>
      1.0 Master
      <br />
      2.0 Trafic interne
      <br />
      3.0 Données brutes
    </p>
    <p>
      D'une manière ou d'une autre, il y a généralement 3 types de vues. Elles peuvent être filtrées davantage par pays
      ou par appareil, si cela a du sens pour votre organisation.
    </p>
    <H as="h3">Exemple : structure de vue Google Analytics pour les sites multinationaux</H>
    <p>
      1.1 Master (US)
      <br />
      1.2 Master (UK)
      <br />
      1.3 Master (DE)
      <br />
      1.4 Master (FR)
      <br />
      1.5 Master (ES)
      <br />
      1.6 Master (MX)
      <br />
      1.7 Master (RUS)
      <br />
      2.0 Trafic interne
      <br />
      3.0 Données brutes
    </p>

    <H as="h3">Exemple : structure de vue Google Analytics régionale</H>
    <p>
      1.0 Master (NA)
      <br />
      1.1 Master (EMEA)
      <br />
      1.2 Master (LATAM)
      <br />
      1.3 Master (APEC)
      <br />
      2.0 Trafic interne
      <br />
      3.0 Données brutes
    </p>

    <p>
  La <strong>vue Master</strong> est la vue principale pour l'analyse et exclut tout le trafic interne.
</p>
<p>
  La <strong>vue de trafic interne</strong> inclut tout le trafic interne. Celle-ci peut également être utilisée à des fins de débogage et de test. Si vous définissez le filtre d'inclusion, tout le reste sera exclu automatiquement.
</p>
<p>
  Au début, Google Analytics crée une première <strong>vue Toutes les données du site web</strong>, avec la propriété GA.
</p>
<p>
  Gardez-la, mais changez le nom en <strong>Données brutes</strong>. Vous voulez avoir une vue des données brutes sans aucun filtre. Vous ne pouvez pas annuler un filtre rétroactivement et consulter les données non filtrées. Par conséquent, il est recommandé de garder une vue non modifiée. Pour changer le nom, allez dans{" "}
  <strong>Admin &gt; Vue &gt; Paramètres de la vue</strong>.
</p>
<p>
  Dans la plupart des cas, les entreprises concentrent leurs efforts sur un ou quelques pays. Dans ce cas, créez des vues par pays et définissez les filtres en conséquence.
  <br />
  Vous pouvez également créer des vues par source de trafic (organique, payant, direct, social, etc.) ou par appareil (bureau, tablette, mobile). J'utilise les segments de Google Analytics pour cela, mais il n'y a pas de raison de ne pas filtrer les spécificités déjà dans la vue. J'aime garder le nombre de vues bas pour maintenir l'interface utilisateur de GA propre.
  <br />Je peux alors être un peu plus désordonné avec les segments dans la vue et appliquer les spécificités restantes là-bas.
</p>
<p>
  Créez toutes les vues dès que possible, car elles ne montrent les données qu'à partir du moment où elles ont été créées. Une nouvelle vue ne montrera aucune donnée historique d'avant la date de création. Si vous avez besoin de consulter l'historique avec des filtres spécifiques, vous revenez à la vue des données brutes. Vous appliquez simplement les filtres souhaités avec des segments à la place. De cette façon, vous obtenez vos informations, mais vous ne modifiez pas les données.
</p>
<p>
  <strong>ASTUCE</strong> : Si certaines vues partagent les mêmes filtres, vous pouvez en créer une et ensuite la dupliquer avec tous les filtres. Ensuite, vous ajoutez simplement les filtres spécifiques à chaque vue aux doublons.
  <br />
  Assurez-vous de ne pas modifier la vue originale, afin de n'ajouter des filtres qu'aux doublons. Modifier la vue originale plus tard affectera également les vues dupliquées.
</p>
</li>
<li>
  <H as="h3">Alertes Google Analytics</H>
  Une autre bonne pratique consiste à <strong>configurer des alertes Google Analytics</strong>. Je les configure pour être notifié des diminutions de sessions ou lorsque le temps de réponse du serveur augmente. De cette façon, je peux examiner les raisons et éventuellement faire quelque chose.
</li>
</ol>
<ul>
  <li>
    Pour configurer une alerte dans Google Analytics, allez dans <strong>Personnalisation</strong> dans le menu latéral.
  </li>
  <li>
    Alternativement, vous pouvez également visiter à nouveau la zone <strong>Admin</strong> et cliquer sur{" "}
    <strong>Alertes personnalisées</strong> dans le panneau de la vue.
  </li>
  <li>
    Cliquez sur <strong>Alertes personnalisées</strong> et cliquez sur <strong>gérer les alertes personnalisées</strong>.
  </li>
  <li>
    Configurer une alerte Google Analytics pour la diminution des sessions est assez simple. Cliquez sur{" "}
    <strong>Nouvelle alerte</strong> et nommez l'alerte par exemple <strong>Diminution des sessions</strong> avec la{" "}
    <strong>Période</strong> définie sur <strong>Semaine</strong>.
  </li>
  <li>Cochez également la case pour recevoir un email lorsque l'alerte se déclenche.</li>{" "}
  <li>
    Il y a généralement des fluctuations de trafic entre les jours de semaine, les week-ends et les jours fériés. Nous ne voulons pas être alertés pour ces fluctuations, donc nous la configurons pour examiner les données de sessions hebdomadaires.{" "}
  </li>
  <li>
    Les <strong>conditions d'alerte</strong> s'appliquent à <strong>tout le trafic</strong> et vous voulez être alerté lorsque <strong>les sessions diminuent de plus de 35% par rapport à la semaine précédente.</strong>
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
  alt="Configurer des alertes Google Analytics pour diminution des sessions"
  className="article-img"
/>

<p>
  Ensuite, cliquez sur <strong>enregistrer</strong> et ajoutons la prochaine alerte.
</p>
<p>Pour configurer une alerte Google Analytics pour une augmentation du temps de réponse du serveur,</p>
<ul>
  <li>
    cliquez sur <strong>Nouvelle alerte</strong> et donnez-lui un nom, comme{" "}
    <em>Augmentation du temps de réponse du serveur jour après jour</em> et
  </li>
  <li>
    sélectionnez <strong>jour</strong> comme période.
  </li>
  <li>
    Pour les <strong>conditions d'alerte</strong>, elles s'appliquent à <strong>tout le trafic</strong> et vous voulez être
    alerté lorsque votre <strong>temps de réponse moyen du serveur augmente de plus de 300%</strong> par rapport au{" "}
    <strong>jour précédent</strong>:
  </li>
</ul>

<ImgScreenshot
  src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
  alt="Configurer des alertes Google Analytics pour le temps de réponse du serveur"
  className="article-img"
/>

<p>
  Ensuite, cliquez sur <strong>enregistrer</strong>.<br />
  Vous pouvez maintenant être sûr de recevoir un email d'alerte lorsque votre trafic diminue ou lorsque votre serveur rencontre des problèmes.
</p>
</MainContent>
</React.Fragment>

</Layout>
);

export default AnalyticsSetup;





  